import dataFetcher from './dataFatcher';

export function gethomedata() {
    return dataFetcher('GET', 'prod/gethomedata');
}

export function getsearchresult(formdata: SearchFormdata) {
    return dataFetcher('POST', 'prod/getsearchresult', formdata);
}

export function getgamedetails(formdata: GameDetailsFormdata) {
    return dataFetcher('POST', 'prod/getgamedetails', formdata);
}
export function getLegalInfo(formdata: LegalFormdata) {
    return dataFetcher('POST', 'prod/getLegalInfo', formdata);
}
export function getgamesbycategory(formdata: CategoryFormdata) {
    return dataFetcher('POST', 'prod/getgamesbycategory', formdata);
}
export function loginApi(formdata: LoginFormdata) {
    return dataFetcher('POST', 'prod/login', formdata);
}
export function signupApi(formdata: SignupFormdata) {
    return dataFetcher('POST', 'prod/signup', formdata);
}

export function getsubschemes(formdata: SubFormdata) {
    return dataFetcher('POST', 'prod/getsubschemes', formdata);
}
export function getsdpurl(formdata: SubReqFormdata) {
    return dataFetcher('POST', 'subscribe/getsdpurl', formdata);
}
export function unsub(formdata: UnsubReqFormdata) {
    return dataFetcher('POST', 'subscribe/unsub', formdata);
}

interface SearchFormdata {
    keyword: string;
    page?: string;
}
interface GameDetailsFormdata {
    gameid: string;
    userid?: string;
}
interface LegalFormdata {
    keyword: string;
}
interface CategoryFormdata {
    catcode: string;
    page: number;
}
interface LoginFormdata {
    msisdn: string;
    password?: string;
    haspin: string;
}
interface SignupFormdata {
    msisdn: string;
}
interface SubFormdata {
    msisdn?: string;
    appname: string;
}
interface SubReqFormdata {
    msisdn: string;
    pack: string;
    renew: string;
}
interface UnsubReqFormdata {
    msisdn: string;
    pack: string;
}
